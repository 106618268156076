import React, { useEffect, useState } from "react";
import styled from "styled-components";

// Styles
import "../styles/plyr.css";

const SingleVideoContainer = styled.div``;

export const SingleVideoEmbed = ({ content, index }) => {
  const [player, setPlayer] = useState(null);
  const [isPlayerReady, setIsPlayerReady] = useState(false);

  useEffect(() => {
    if (typeof window !== "undefined" && typeof document !== "undefined") {
      const Plyr = require("plyr");

      setPlayer(
        new Plyr(`#video-banner${content.id}`, {
          autoplay: false,
          autopause: true,
          loop: { active: true },
          controls: ["play-large"],
          hideControls: true,
          clickToPlay: true,
          playsinline: true,
          muted: true,
          volume: 1,
          vimeo: {
            autopause: false,
            background: true,
            byline: false,
            controls: false,
            title: false,
            transparent: true,
          },
          youtube: {
            modestbranding: 1,
            playsinline: 1,
            controls: 0,
            loop: 1,
            rel: 0,
          },
        })
      );
    }
  }, []);

  useEffect(() => {
    if (player !== null) {
      player.on("playing", () => {
        setIsPlayerReady(true);
      });
    }
  }, [player]);

  return (
    <SingleVideoContainer
      className={`module-video-mp4 module-item-${index}`}
      key={`single_page_item_${index}_${content.id}`}
    >
      <div
        className="plyr__video-embed"
        id={`video-banner${content.id}`}
        dangerouslySetInnerHTML={{
          __html: content.primary.video.html,
        }}
      />
    </SingleVideoContainer>
  );
};
