import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Carousel } from "react-responsive-carousel";
import "../styles/gallery.css";

// Utils
import { ImageOrientation } from "../utils/image-orientation";
import { AspectRatioImageContainer } from "../containers/aspect-ratio-image-container";

// Components

const GalleryContainer = styled.div`
  position: relative;

  width: 100%;

  & .gallery-dots {
    width: 100%;
    display: block;
    margin: 10px 0 0 0;
    text-align: center;
    width: 100%;

    & li {
      background: #fff;
      border: 3px solid #000;
      width: 25px;
      height: 25px;
      cursor: pointer;
      display: inline-block;
      margin: 0 25px;

      @media (max-width: 1350px) {
        margin: 0 15px;
      }

      @media (max-width: 1100px) {
        width: 20px;
        height: 20px;
      }

      @media (max-width: 769px) {
        margin: 0 10px;
        border: 2px solid #000;

        width: 10px;
        height: 10px;
      }

      &:hover {
        background: #000;
      }

      &.active {
        background: #000;
      }
    }
  }

  &.no-controls {
    & .control-dots,
    & .control-arrow.control-disabled {
      display: none;
    }
  }

  & .image-gallery {
    & .slider-wrapper {
      max-width: 900px;
      margin: 0 auto;

      @media (max-height: 800px) {
        max-width: 700px;
      }
    }
  }

  & .carousel.carousel-slider .control-arrow {
    padding: 0;
    width: 50%;

    &.control-prev {
      left: 0;

      cursor: url("/icons/previous-arrow@1x.png") 50 0, pointer;
      cursor: -webkit-image-set(
            url("/icons/previous-arrow@1x.png") 1x,
            url("/icons/previous-arrow@2x.png") 2x
          )
          50 0,
        pointer;
      cursor: url("/icons/previous-arrow.svg"), 50 0, pointer;
    }

    &.control-next {
      right: 0;

      cursor: url("/icons/next-arrow@1x.png") 50 0, pointer;
      cursor: -webkit-image-set(
            url("/icons/next-arrow@1x.png") 1x,
            url("/icons/next-arrow@2x.png") 2x
          )
          50 0,
        pointer;
      cursor: url("/icons/next-arrow.svg"), 50 0, pointer;
    }
  }

  & .image-caption {
    margin: 5px 0 0 0;
    font-size: 16px;
    width: 100%;
    text-align: center;

    @media (max-width: 769px) {
      font-size: 14px;
    }
  }
`;

const ImageContainer = styled.div`
  position: relative;

  width: 100%;
  height: 100%;

  display: inline-block;

  & img {
    max-width: 100%;
    max-height: 100%;
    // object-fit: cover;

    // &.portrait {
    object-fit: contain;
    // }
  }
`;

export const Gallery = ({ images, opacity, autoplay }) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [allGalleryContent, setAllGalleryContent] = useState([]);
  const [totalSlides, setTotalSlides] = useState(null);

  useEffect(() => {
    setTotalSlides(images.length);
    setAllGalleryContent(images);
  }, []);

  const updateCurrentSlide = index => {
    if (currentSlide !== index) {
      setCurrentSlide(index);
    }
  };

  const captions = images.filter(image => image.image.fluid !== null);

  const galleryContent = images
    .filter(image => image.image.fluid !== null)
    .map((content, index) => {
      return (
        <ImageContainer key={`project_images_${index}`}>
          <AspectRatioImageContainer image={null} padding={`75`}>
            <img
              className={ImageOrientation(content.image)}
              srcSet={content.image.fluid.srcSetWebp}
              src={content.image.fluid.srcWebp}
              alt={content.image.alt !== null ? content.image.alt : ``}
              loading={
                index <= 2 || index === images.length - 1 ? `eager` : `lazy`
              }
            />
          </AspectRatioImageContainer>
        </ImageContainer>
      );
    });

  let galleryIndicators = [];
  for (let i = 0; i < totalSlides; i++) {
    galleryIndicators.push(
      <li
        key={i}
        onClick={() => updateCurrentSlide(i)}
        className={currentSlide === i ? `active` : ``}
      ></li>
    );
  }

  return (
    <GalleryContainer
      opacity={opacity}
      className={galleryContent.length === 1 ? `no-controls` : ``}
    >
      <Carousel
        showThumbs={false}
        autoPlay={false}
        // showIndicators={true}
        showIndicators={false}
        showStatus={false}
        infiniteLoop={true}
        useKeyboardArrows={true}
        className="image-gallery"
        selectedItem={currentSlide}
        onChange={index => updateCurrentSlide(index)}
        stopOnHover={false}
        transitionTime={0}
      >
        {galleryContent}
      </Carousel>

      <p className="image-caption">
        {captions[currentSlide] !== undefined
          ? captions[currentSlide].image.alt
          : ``}
      </p>

      <ol className="gallery-dots">{galleryIndicators}</ol>
    </GalleryContainer>
  );
};
